import React, { PropTypes } from 'react';
import GSPromoImage from 'bbc-morph-gs-promo-image';

function isImageOnly(promoType) {
  return promoType === 'featured';
}

function getImgClassName(promoType) {
  if (promoType === 'featured') {
    return 'gs-u-display-none gs-u-display-block@xs sp-c-feature-promo__img';
  }

  return '';
}

function getSizes(promoType) {
  if (promoType === 'featured') {
    return '(min-width: 90em) 25vw, (min-width: 80em) 33vw, (min-width: 56.25em) 40vw, (min-width: 37.5em) 60vw, calc(100vw - 32px)';
  }

  return '';
}

function getClassName(promoType) {
  switch (promoType) {
    case 'lead':
      return ['gs-u-display-none', 'gs-u-display-block@xs', 'gel-2/3@m', 'gs-u-pl0', 'gs-u-mr'];
    case 'leadStacked':
      return [
        'gs-u-display-none',
        'gs-u-display-block@xs',
        'gel-2/3@m',
        'gel-1/1@xxl',
        'gs-u-pl0',
        'gs-u-mr',
      ];
    case 'leadStackedToInline':
      return ['gs-u-display-none', 'gs-u-display-block@xs', 'gel-2/3@m', 'gel-1/2@l'];
    case 'stackedToInline':
      return ['gs-u-display-none', 'gs-u-display-block@xs', 'gel-1/2@l', 'gs-u-mb@m', 'gs-u-mb0@l'];
    case 'stacked':
      return ['gs-u-display-none', 'gs-u-display-block@xs', 'gel-1/2@xs', 'gel-1/1@m'];
    case 'orderedList':
      return ['gel-pica-bold', 'sp-o-link-split__text'];
    case 'multiThumb':
      return [
        'gs-u-pl-@xs',
        'gs-u-pl0@m',
        'gs-u-pl+@l',
        'gs-u-display-none',
        'gs-u-display-block@xs',
      ];
    case 'multiThumbInline':
      return [
        'gs-u-pl-@xs',
        'gs-u-pl0@m',
        'gs-u-pl+@l',
        'gs-u-display-none',
        'gs-u-display-block@xs',
        'gs-u-pr0@l',
        'gs-u-pr@m',
      ];
    default:
      return ['gs-u-display-none', 'gs-u-display-block@xs'];
  }
}

function isDelayed(promoType) {
  const delayedPromos = [
    'featured',
    'lead',
    'leadStacked',
    'leadStackedToInline',
    'stacked',
    'stackedToInline',
    'multiThumbInline',
    'multiThumb',
  ];
  return delayedPromos.includes(promoType);
}

const Image = ({ children, url, altText, width }, { promoType }) => (
  <GSPromoImage
    product="onesport"
    ichefRecipe={url}
    alt={altText}
    className={getClassName(promoType)}
    imgClassName={getImgClassName(promoType)}
    sizes={getSizes(promoType)}
    imageOnly={isImageOnly(promoType)}
    delayed={isDelayed(promoType)}
    width={width}
  >
    {children}
  </GSPromoImage>
);

Image.displayName = 'SportPromoImage';

Image.contextTypes = {
  promoType: PropTypes.string,
};

Image.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  width: PropTypes.number,
};

Image.defaultProps = {
  children: null,
  width: null,
};

export default Image;
