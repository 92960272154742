import React, { PropTypes } from 'react';
import classNamesHelper from 'bbc-morph-classnames';
import GSPromoHeading from 'bbc-morph-gs-promo-heading';

function getTitleClasses(promoType) {
  switch (promoType) {
    case 'featured':
      return [
        'sp-o-link-split__text',
        'sp-c-feature-promo__line',
        'sp-o-link-split__text--white',
        'gs-u-display-block',
      ];
    case 'liveGuide':
      return ['live-guide-promo__link-text'];
    default:
      return ['sp-o-link-split__text'];
  }
}

function getLinkClasses(promoType) {
  switch (promoType) {
    case 'featured':
      return [];
    case 'lead':
    case 'leadStacked':
      return [
        'gs-o-faux-block-link__overlay-link',
        'sp-o-link-split__anchor',
        'gel-double-pica-bold',
      ];
    case 'liveGuide':
      return [
        'gs-o-faux-block-link__overlay-link',
        'live-guide-promo__link-anchor',
        'gel-pica-bold',
      ];
    case 'MAP':
      return ['gs-o-faux-block-link__overlay-link', 'sp-o-link-split__anchor', 'gs-u-mv'];
    case 'orderedList':
      return ['sp-o-link', 'gel-pica-bold'];
    default:
      return ['gs-o-faux-block-link__overlay-link', 'sp-o-link-split__anchor', 'gel-pica-bold'];
  }
}

function getClassName(promoType, classNames) {
  switch (promoType) {
    case 'featured':
      return ['sp-c-feature-promo__heading'];
    case 'liveGuide':
      return ['sp-c-promo__heading--flex'];
    case 'MAP':
      return ['gs-u-display-none', 'gs-u-display-block@xs'].concat(classNames);
    default:
      return [];
  }
}

function getHeadingType(promoType) {
  switch (promoType) {
    case 'MAP':
      return 'h4';
    case 'orderedList':
      return 'span';
    default:
      return 'h3';
  }
}

function getTitleTypographyClass(promoType) {
  switch (promoType) {
    case 'featured':
      return 'gel-great-primer-bold';
    case 'lead':
    case 'leadStacked':
      return 'gel-double-pica-bold';
    case 'MAP':
      return 'gel-pica-bold sp-o-link-split__text--on-dark';
    default:
      return 'gel-pica-bold';
  }
}

const Heading = (
  { children, classNames, title, url, linkOnClick, accessibilityHeading, summary },
  { promoType },
) => (
  <div className={classNamesHelper(getClassName(promoType, classNames))}>
    <GSPromoHeading
      title={title}
      url={url}
      linkCallback={linkOnClick}
      accessibilityHeading={accessibilityHeading}
      headingType={getHeadingType(promoType)}
      linkClasses={getLinkClasses(promoType)}
      titleClasses={getTitleClasses(promoType)}
      titleTypographyClass={getTitleTypographyClass(promoType)}
    >
      {children}
    </GSPromoHeading>
    {summary}
  </div>
);

Heading.displayName = 'SportPromoHeading';

Heading.contextTypes = {
  promoType: PropTypes.string,
};

Heading.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.bool)])),
    PropTypes.objectOf(PropTypes.bool),
    PropTypes.string,
  ]),
  url: PropTypes.string,
  title: PropTypes.string,
  linkOnClick: PropTypes.func,
  accessibilityHeading: PropTypes.string,
  summary: PropTypes.node,
};

Heading.defaultProps = {
  children: null,
  classNames: [],
  url: '',
  title: '',
  linkOnClick: null,
  accessibilityHeading: '',
  summary: null,
};

export default Heading;
