import React, { PropTypes } from 'react';
import GSCommentIcon from 'bbc-morph-gs-comment-icon';

const CommentIcon = ({ assetUri, commentsCount }) => (
  <GSCommentIcon assetUri={assetUri} commentsCount={commentsCount} theme="sport" />
);

CommentIcon.displayName = 'SportPromoCommentIcon';

CommentIcon.propTypes = {
  assetUri: PropTypes.string,
  commentsCount: PropTypes.number,
};

CommentIcon.defaultProps = {
  assetUri: '',
  commentsCount: null,
};

export default CommentIcon;
