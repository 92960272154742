import React, { PropTypes } from 'react';
import GSSectionTag from 'bbc-morph-gs-section-tag';

function isOnDark(promoType) {
  return promoType === 'MAP';
}

const SectionTag = ({ sectionName, sectionUrl }, { promoType }) => (
  <GSSectionTag onDark={isOnDark(promoType)} item={{ sectionName, sectionUrl }} />
);

SectionTag.displayName = 'SportPromoSectionTag';

SectionTag.contextTypes = {
  promoType: PropTypes.string,
};

SectionTag.propTypes = {
  sectionName: PropTypes.string,
  sectionUrl: PropTypes.string,
};

SectionTag.defaultProps = {
  sectionName: 'Sport',
  sectionUrl: '/sport',
};

export default SectionTag;
