import React, { PropTypes } from 'react';
import classNames from 'bbc-morph-classnames';

function getClassNames(promoType) {
  switch (promoType) {
    case 'featured':
      return ['sp-c-feature-promo', 'sp-o-link-split__anchor', 'gs-u-display-block'];
    default:
      return ['sp-o-link-split__anchor'];
  }
}

const Link = ({ href, onClick, children }, { promoType }) => (
  <a className={classNames(getClassNames(promoType))} href={href} onClick={onClick}>
    {children}
  </a>
);

Link.displayName = 'SportPromoLink';

Link.contextTypes = {
  promoType: PropTypes.string,
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Link.defaultProps = {
  onClick: null,
  children: null,
};

export default Link;
