import React, { PropTypes } from 'react';
import PromoSummary from 'bbc-morph-gs-promo-summary';

function getSummaryClasses(promoType) {
  switch (promoType) {
    case 'leadStackedToInline':
      return [
        'sp-c-promo-summary',
        'gs-u-display-none',
        'gs-u-display-block@m',
        'gs-u-mt@m',
        'gs-u-display-none@l',
      ];
    case 'multiThumb':
    case 'multiThumbInline':
      return ['sp-c-promo-summary', 'gs-u-mt-@m'];
    default:
      return ['sp-c-promo-summary', 'gs-u-display-none', 'gs-u-display-block@m', 'gs-u-mt@m'];
  }
}

const Summary = ({ text }, { promoType }) => (
  <PromoSummary summaryClasses={getSummaryClasses(promoType)} text={text} />
);

Summary.displayName = 'SportPromoSummary';

Summary.contextTypes = {
  promoType: PropTypes.string,
};

Summary.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Summary;
