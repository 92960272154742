import Body from './components/Body';
import CommentIcon from './components/CommentIcon';
import Heading from './components/Heading';
import Image from './components/Image';
import ItemStatus from './components/ItemStatus';
import Link from './components/Link';
import LivePulse from './components/LivePulse';
import MediaIndicator from './components/MediaIndicator';
import Meta from './components/Meta';
import Promo from './components/Promo';
import SectionTag from './components/SectionTag';
import Summary from './components/Summary';

export default {
  Body,
  CommentIcon,
  Heading,
  Image,
  ItemStatus,
  Link,
  LivePulse,
  MediaIndicator,
  Meta,
  Promo,
  SectionTag,
  Summary,
};
