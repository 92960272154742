import React, { PropTypes } from 'react';
import GSPromoMeta from 'bbc-morph-gs-promo-meta';

const Meta = ({ children }) => <GSPromoMeta classes="gs-u-mt-">{children}</GSPromoMeta>;

Meta.displayName = 'SportPromoMeta';

Meta.propTypes = {
  children: PropTypes.node,
};

Meta.defaultProps = {
  children: null,
};

export default Meta;
