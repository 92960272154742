import React, { PropTypes } from 'react';
import GSItemStatus from 'bbc-morph-gs-item-status';
import { formatOnScreenDate, formatOffScreenDate } from '../helpers/date';

const ItemStatus = ({ itemStatusDateTime, nowDateTime, isLive }) => {
  const onScreenDateTime = formatOnScreenDate(itemStatusDateTime, nowDateTime);
  const offScreenDateTime = formatOffScreenDate(itemStatusDateTime, nowDateTime);

  return (
    <GSItemStatus
      className={['sp-c-promo-meta']}
      status={isLive ? 'LIVE' : ''}
      text={isLive ? 'LIVE' : ''}
      offScreenDateTime={offScreenDateTime}
      onScreenDateTime={onScreenDateTime}
      dateTime={itemStatusDateTime}
    />
  );
};

ItemStatus.displayName = 'SportPromoItemStatus';

ItemStatus.propTypes = {
  itemStatusDateTime: PropTypes.string.isRequired,
  isLive: PropTypes.bool,
  nowDateTime: PropTypes.string,
};

ItemStatus.defaultProps = {
  isLive: false,
  nowDateTime: null,
};

export default ItemStatus;
