import React, { PropTypes } from 'react';
import GSPromo from 'bbc-morph-gs-promo';

function getClassName(promoType) {
  switch (promoType) {
    case 'featured':
      return ['gs-u-pb', 'gs-u-pb++@m', 'gs-u-mh0'];
    case 'lead':
      return [
        'gs-c-promo--stacked',
        'gs-c-promo--inline@m',
        'gs-o-faux-block-link',
        'gs-u-pb',
        'gs-u-pb++@m',
        'gel-1/1',
        'gs-u-ml0',
      ];
    case 'leadStacked':
      return [
        'gs-c-promo--stacked',
        'gs-c-promo--inline@m',
        'gs-o-faux-block-link',
        'gs-u-pb',
        'gs-u-pb0@m',
        'gel-1/1',
        'gs-u-ml0',
        'gs-c-promo--stacked@xxl',
      ];
    case 'stacked':
      return [
        'gs-c-promo--stacked@m',
        'gs-c-promo--inline',
        'gs-o-faux-block-link',
        'gs-u-pb',
        'gs-u-pb++@m',
      ];
    case 'leadStackedToInline':
      return [
        'gs-c-promo--stacked',
        'gs-c-promo--inline@m',
        'gs-o-faux-block-link',
        'gs-u-pb',
        'gs-u-pb++@m',
      ];
    case 'liveGuide':
      return ['gs-c-promo--inline', 'gs-c-promo--stacked@m', 'gs-o-faux-block-link', 'gs-u-pb++@m'];
    case 'MAP':
      return ['gs-o-faux-block-link', 'gs-u-pb', 'gs-u-pb+@m'];
    case 'orderedList':
      return ['gs-u-mh0'];
    case 'stackedToInline':
      return [
        'gs-c-promo--inline',
        'gs-c-promo--stacked@m',
        'gs-o-faux-block-link',
        'gs-u-pb',
        'gs-u-pb++@m',
        'gs-c-promo--inline@l',
      ];
    case 'multiThumb':
      return ['gs-c-promo--stacked@m', 'gs-o-faux-block-link', 'gs-u-mb+@xs'];
    case 'multiThumbInline':
      return [
        'gs-c-promo--inline',
        'gs-c-promo--stacked@l',
        'gs-o-faux-block-link',
        'gs-u-mb+@xs',
        'gs-u-pl-@xs',
        'gs-u-pl0@m',
      ];
    default:
      return [];
  }
}

const Promo = ({ attributes, children }, { promoType }) => (
  <GSPromo className={getClassName(promoType)} theme="sport" attributes={attributes} flex>
    {children}
  </GSPromo>
);

Promo.displayName = 'SportPromo';

Promo.contextTypes = {
  promoType: PropTypes.string,
};

Promo.propTypes = {
  attributes: PropTypes.shape({
    ref: PropTypes.func,
  }),
  children: PropTypes.node,
};

Promo.defaultProps = {
  attributes: null,
  children: null,
};

export default Promo;
