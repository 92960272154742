import React, { PropTypes } from 'react';
import GSMediaIndicator from 'bbc-morph-gs-media-indicator';

function getClassName(promoType) {
  if (promoType === 'orderedList') {
    return 'gs-u-align-test-top gs-u-mr';
  }

  return 'gs-o-media-island__icon';
}

function getColourMode(promoType, isLive) {
  if (promoType === 'MAP' && !isLive) {
    return 'black';
  }

  if (isLive === true) {
    return 'blue';
  }

  return 'white';
}

function isInline(promoType) {
  return promoType === 'orderedList';
}

const MediaIndicator = ({ onScreenDuration, mediaType, isLive, iconSize }, { promoType }) => (
  <GSMediaIndicator
    className={getClassName(promoType)}
    onScreenDuration={onScreenDuration}
    mediaType={mediaType.toLowerCase()}
    inline={isInline(promoType)}
    colourMode={getColourMode(promoType, isLive)}
    iconSize={iconSize}
  />
);

MediaIndicator.displayName = 'SportPromoMediaIndicator';

MediaIndicator.contextTypes = {
  promoType: PropTypes.string,
};

MediaIndicator.propTypes = {
  onScreenDuration: PropTypes.string,
  mediaType: PropTypes.string,
  isLive: PropTypes.bool,
  iconSize: PropTypes.string,
};

MediaIndicator.defaultProps = {
  mediaType: 'video',
  onScreenDuration: '',
  isLive: false,
  iconSize: '',
};

export default MediaIndicator;
