import React, { PropTypes } from 'react';
import GSPromoBody from 'bbc-morph-gs-promo-body';

function getClassName(promoType) {
  switch (promoType) {
    case 'lead':
      return ['gel-1/3@m', 'gs-u-mt@xs', 'gs-u-mt0@m', 'gs-u-pl0', 'gs-u-pl@m'];
    case 'leadStacked':
      return [
        'gel-1/3@m',
        'gs-u-mt@xs',
        'gs-u-mt0@m',
        'gs-u-pl@m',
        'gs-u-pl0@xxl',
        'gel-1/1@xxl',
        'gs-u-mt@xxl',
      ];
    case 'leadStackedToInline':
      return ['gel-1/3@m', 'gs-u-mt@xs', 'gs-u-mt0@m', 'gel-1/2@l'];
    case 'liveGuide':
      return ['gs-u-mt@m'];
    case 'stacked':
      return ['gs-u-mt@m', 'gel-1/2@xs', 'gel-1/1@m'];
    case 'stackedToInline':
      return ['gel-1/2@l'];
    case 'multiThumb':
      return ['sp-c-promo', 'gs-u-mt@xs', 'gel-1/1@m', 'gs-u-pl0@m', 'gs-u-pl+@l'];
    case 'multiThumbInline':
      return ['sp-c-promo', 'gs-u-mt@l', 'gs-u-pl@m', 'gs-u-pl+@l'];
    default:
      return [];
  }
}

function isWhiteText(promoType) {
  return promoType === 'MAP';
}

const Body = ({ children }, { promoType }) => (
  <GSPromoBody className={getClassName(promoType)} whiteText={isWhiteText(promoType)}>
    {children}
  </GSPromoBody>
);

Body.displayName = 'SportPromoBody';

Body.contextTypes = {
  promoType: PropTypes.string,
};

Body.propTypes = {
  children: PropTypes.node,
};

Body.defaultProps = {
  children: null,
};

export default Body;
