import compareAsc from 'date-fns/compare_asc';
import differenceInCalendarMonths from 'date-fns/difference_in_calendar_months';
import format from 'date-fns/format';
import distanceInWords from 'date-fns/distance_in_words';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import isSameDay from 'date-fns/is_same_day';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';

const getFutureTimeToday = itemStatusDate => format(itemStatusDate, 'HH:mm');

const getFutureTimeTomorrow = itemStatusDate => {
  const time = format(itemStatusDate, 'HH:mm');
  return `Tomorrow at ${time}`;
};

const getFutureTimeWithinOneWeek = itemStatusDate => {
  const day = format(itemStatusDate, 'dddd');
  const time = format(itemStatusDate, 'HH:mm');
  return `${day} at ${time}`;
};

const getFutureTimeWithinTwoMonths = itemStatusDate => {
  const day = format(itemStatusDate, 'D MMM');
  const time = format(itemStatusDate, 'HH:mm');
  return `${day} at ${time}`;
};

const getFutureTimeAfterTwoMonths = itemStatusDate => {
  const day = format(itemStatusDate, 'D MMM YYYY');
  const time = format(itemStatusDate, 'HH:mm');
  return `${day} at ${time}`;
};

const getOffScreenDateInPast = (displayDate, now) => {
  const dateDifference = distanceInWords(displayDate, now);
  return `${dateDifference} ago`;
};

const getOffScreenDateInFuture = (displayDate, now) => {
  const dateDifference = distanceInWords(displayDate, now);
  return `Coverage starts in ${dateDifference}`;
};

const getDateInFuture = (itemStatusDate, now) => {
  const today = isSameDay(itemStatusDate, now);
  const tomorrow = differenceInCalendarDays(itemStatusDate, now) === 1;
  const withinWeek = differenceInCalendarDays(itemStatusDate, now) < 7;
  const withinTwoMonths = differenceInCalendarMonths(itemStatusDate, now) <= 1;

  if (today) {
    return getFutureTimeToday(itemStatusDate);
  }
  if (tomorrow) {
    return getFutureTimeTomorrow(itemStatusDate);
  }
  if (withinWeek) {
    return getFutureTimeWithinOneWeek(itemStatusDate);
  }
  if (withinTwoMonths) {
    return getFutureTimeWithinTwoMonths(itemStatusDate);
  }
  return getFutureTimeAfterTwoMonths(itemStatusDate);
};

const getDateInPast = (itemStatusDate, now) => {
  const inWords = distanceInWords(itemStatusDate, now)
    .replace(/(about )/, '')
    .replace(/( days?)/, 'd')
    .replace(/( hours?)/, 'h');

  if (inWords === 'less than a minute') {
    return distanceInWordsStrict(itemStatusDate, now).replace(/( seconds?)/, 's');
  }

  const numMinutes = inWords.match(/(\d+) minute/);
  if (numMinutes) {
    return `${numMinutes[1]}m`;
  }

  if (inWords.match(/( months?)/)) {
    return format(itemStatusDate, 'D MMM');
  }
  if (inWords.match(/( years?)/)) {
    return format(itemStatusDate, 'D MMM YYYY');
  }

  return inWords;
};

function dateInFuture(testDate, nowDate) {
  return compareAsc(testDate, nowDate) === 1;
}

const formatDate = (itemStatus, now, handlers) => {
  const nowDate = now ? new Date(now) : new Date();
  const itemStatusDate = new Date(itemStatus);

  return dateInFuture(itemStatusDate, nowDate)
    ? handlers.itemStatusInFuture(itemStatusDate, nowDate)
    : handlers.itemStatusInPast(itemStatusDate, nowDate);
};

const formatOnScreenDate = (itemStatus, now) =>
  formatDate(itemStatus, now, {
    itemStatusInFuture: getDateInFuture,
    itemStatusInPast: getDateInPast,
  });

const formatOffScreenDate = (itemStatus, now) =>
  formatDate(itemStatus, now, {
    itemStatusInFuture: getOffScreenDateInFuture,
    itemStatusInPast: getOffScreenDateInPast,
  });

export { formatOnScreenDate, formatOffScreenDate };
